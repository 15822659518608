import React, {useEffect} from 'react'
import './header.css'
import mouse from '../../assets/images/mouse.png'
import headerCover from '../../assets/images/headerCover.png'

export default function Header(props) {
    let hidingSlideIndex = 1
    let scrollToAboutUs = () => {
        console.log('scrolling')
        document.getElementById('aboutUs').scrollIntoView({behavior: 'smooth', block: 'center'})
    }
    useEffect(() => {
        $(function () {
            $('.slidesContainer #2').hide();
            setInterval(function () {
                let div = $(`.slidesContainer #${hidingSlideIndex}`)
                div.fadeOut(1000, () => {
                    let toShowIndex = hidingSlideIndex == 2 ? 1 : 2
                    $(`.slidesContainer #${toShowIndex}`).fadeIn(1000, () => {
                        let secondDiv = $(`.slidesContainer #${toShowIndex}`)
                        secondDiv.css("display", "block")
                        hidingSlideIndex = hidingSlideIndex == 2 ? 1 : 2
                    })
                })
            }, 7000);
        });
    }, [])

    return (
        <div className={'container-fluid w-100'}>
            <div className={'header w-100'}>
                <div className={'d-flex flex-column innerHeader'}>
                    <div className={'slidesContainer container text-white col d-flex flex-column align-items-start justify-content-center'}>
                        <div id={'1'}>
                            <h1 style={{fontSize:'4em !important'}}>{'we organize'}</h1>
                        </div>
                        <div id={'2'}>
                            <h1>{'we achieve'}</h1>
                        </div>
                        <h5>{'Red Eagle Company is a company specialized in  organizing events,\ntrade shows and international exhibitions in Iraq'}</h5>
                    </div>
                    <div className={'container text-center align-items-end d-flex align-center d-sm-block d-none'}>
                        <div onClick={() => scrollToAboutUs()} className={'col mb-4 mouseBtn'}>
                            <img src={mouse} className={'img-fluid mouse'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
