import React from 'react'
import redishColumn from '../../assets/images/redishColumn.svg'
import commaUpRight from '../../assets/images/commaUpRight.svg'
import commaUpLeft from '../../assets/images/commaUpLeft.svg'
import eventsImage from '../../assets/images/eventsImage.png'
import hitex from '../../assets/images/hitex.png'
import './events.css'

export default function AboutUs() {
    return (
        <div className={'container'}>
            <div className={'pt-5 container-fluid'}>
                <div className={'d-flex title'}>
                    <img src={redishColumn}/>
                    <h2 className={'m-2'}><b className={'title'}>events</b></h2>
                </div>
                <div className={'row container-fluid mt-4'}>
                    <div className={'col-sm-12 col-lg-6 d-flex flex-column mb-5'}>
                        <div>
                            <img src={commaUpRight} className={'img-fluid comma'}/>
                        </div>
                        <div className={'align-self-center'}>
                            <p>
                                {'Red Eagle Is The Organizer Of The HITEX Exhibition, The Biggest Information\n'}
                                {'Technology Exhibition In Kurdistan And Iraq.\n'}
                            </p>
                            <p>
                                {'The Hawler Information Technology Exhibition (HITEX) Is An Annual Computer \n'}
                                {'And Electronics Exhibition, And Conference Held At The Erbil International \n'}
                                {'Fairground In Erbil, Kurdistan.\n'}
                            </p>
                        </div>
                        <div className={'d-flex justify-content-end'}>
                            <img src={commaUpLeft} className={'img-fluid comma'}/>
                        </div>
                    </div>
                    <div className={'d-flex justify-content-center align-items-center col-sm-12 col-lg-6 flexImages'}>
                        <div className={'px-2'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.021" height="28.114" viewBox="0 0 19.021 28.114">
                                <path id="Path_4" data-name="Path 4" d="M.243,0,10.694,11.76,0,22.655" transform="translate(16.166 25.457) rotate(180)" fill="none" stroke="#82878c" stroke-width="8"/>
                            </svg>
                        </div>
                        <div className={'imageContainer w-75'}>
                            <img src={hitex} className={'img-fluid hitexImage'}/>
                            <br/>
                            <img src={eventsImage} className={'img-fluid eventsImage'}/>
                        </div>
                        <div className={'px-2'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.021" height="28.114" viewBox="0 0 19.021 28.114">
                                <path id="Path_3" data-name="Path 3" d="M1255.552,407,1266,418.76l-10.695,10.895" transform="translate(-1252.455 -404.343)" fill="none" stroke="#82878c" stroke-width="8"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
