import React,{useEffect} from 'react';
import {Switch,Route} from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import routes from "./routes"
import './assets/styles/global.css'

const rtlLocales = ['ar', 'sr', 'ba'];

function App() {
  const { i18n } = useTranslation();

  function onLangChangeHandler(lang) {
    const html = document.getElementsByTagName('html')[0];
    const splitLang = lang.split('-');

    if (splitLang[0] === 'en') {
      html.classList.remove('rtl');
    } else {
      html.classList.add('rtl');
    }
  }

  useEffect(() => {
    onLangChangeHandler(i18n.language);
  });

  return (
    <>
      <Switch>
          {routes.map(r=>(
              <Route path={r.path} key={r.path} exact={true}>
                  {React.createElement(r.view, {
                    key:r.key
                  })}
              </Route>
          ))}
      </Switch>
    </>
  );
}

export default App;
