import React from 'react'
import redishColumn from '../../assets/images/redishColumn.svg'
import mailIcon from '../../assets/images/mailIcon.svg'
import phoneIcon from '../../assets/images/phoneIcon.svg'
import './contacts.css'

export default function Contacts() {
    return (
        <div  className={'container'}>
            <div className={'container-fluid'}>
                <div className={'d-flex title pt-5'}>
                    <img src={redishColumn}/>
                    <h2 className={'m-2'}><b>contact us</b></h2>
                </div>
                <div className={'d-md-none mx-3 mt-5'}>
                    <p>
                        For Any Inquiries Please Contact Us At:
                    </p>
                </div>
                <div className={'row container-fluid pb-5'}>
                    <div className={'col-6 d-flex flex-column align-items-center px-0'}>
                        <div>
                            <div className={'d-none d-md-block'}>
                                <p>
                                    For Any Inquiries Please Contact Us At:
                                </p>
                            </div>
                            <br/>
                            <div className={'contactsTextInfo'}>
                                <img src={mailIcon} className={'mailIcon'}/>
                                <div className={'pill'}/>
                                <div className={'col text'}>
                                    <p>Sales@Redeaglexpo.Com</p>
                                    <p>Info@Redeaglexpo.Com</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'col-6 d-flex flex-column align-items-center px-0'}>
                        <div>
                            <div className={'d-none d-md-block'}>
                                <p>
                                    Or Call Us On:
                                </p>
                            </div>
                            <br/>
                            <div className={'contactsTextInfo'}>
                                <img src={phoneIcon} className={'phoneIcon'}/>
                                <div className={'pill'}/>
                                <div className={'col text'}>
                                    <p>+964 750 012 4000</p>
                                    <p>+964 750 012 5000</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
