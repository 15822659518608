import React from 'react'
import './footer.css'

export default function Footer(){
    return (
        <div className={'container-fluid footerClass'}>
            <span>Developed and designed by Lucid Source LTD</span>
        </div>
    )
}
