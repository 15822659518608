import React from 'react'
import redishColumn from '../../assets/images/redishColumn.svg'
import commaUpRight from '../../assets/images/commaUpRight.svg'
import commaUpLeft from '../../assets/images/commaUpLeft.svg'
import aboutUsGridImage from '../../assets/images/aboutUsImages.png'

export default function AboutUs() {
    return (
        <div className={'container'} id={'aboutUs'}>
            <div className={'pt-5 container-fluid'}>
                <div className={'d-flex title'}>
                    <img src={redishColumn}/>
                    <h2 className={'m-2'}><b className={'title'}>about us</b></h2>
                </div>
                <div className={'row container-fluid mt-4'}>
                    <div className={'col-sm-12 col-lg-6 d-flex flex-column mb-5'}>
                        <div>
                            <img src={commaUpRight} className={'img-fluid comma'}/>
                        </div>
                        <div className={'align-self-center'}>
                            <p>
                                {'We Are Red Eagle Company, The Organizer Of HITEX, We Organized And \nManaged The Exhibition With A Young And Capable Team In Partnership With\nThe Rwanga Foundation. As A Result, We Were Able To Run A Successful\nTechnology Exhibition.'}
                            </p>
                            <p>
                                {'Furthermore, We Tried To Step Forward To The Level Of Developed Countries, Grab\n'}
                                {'The Local And International Companies’ Attention To The World Of Technology, And\n'}
                                {'Convey The Message That We Can Be An Essential Part Of Technological\n'}
                                {'Development.'}
                            </p>
                        </div>
                        <div className={'d-flex justify-content-end'}>
                            <img src={commaUpLeft} alt={'comma'} className={'img-fluid comma'}/>
                        </div>
                    </div>
                    <div className={'col-sm-12 col-lg-6 d-flex align-items-center justify-content-center'}>
                        <img src={aboutUsGridImage} className={'img-fluid flexImages'}/>
                    </div>
                </div>

            </div>
        </div>
    )
}
