import React from 'react'
import Header from "../components/Header/Header"
import AboutUs from "../components/AboutUs/AboutUs"
import Events from '../components/events/Events'
import Footer from '../components/Footer/Footer'
import Contacts from "../components/Contacts/Contacts"

function Home() {
    return (
        <div className={'container-fluid'}>
            <Header/>
            <div className={'mainContainer'}>
                <AboutUs/>
                <Events/>
                <Contacts/>
            </div>
            <Footer/>
        </div>
    )
}

export default  Home
